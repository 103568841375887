import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[appFadeInImage]',
  standalone: true
})
export class FadeInImageDirective {
  private readonly elementRef: ElementRef = inject(ElementRef);

  @HostListener('load')
  public onLoad(): void {
    this.elementRef.nativeElement.classList.add('fade-in');
  }
}
