<div class="spinner-container">
  <svg
    width="82px"
    height="82px"
    viewBox="0 0 82 82"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41 9.08929C41 9.08929 41 0 41 0C32.8909 0 24.9641 2.4046 18.2217 6.90976C11.4792 11.4149 6.22412 17.8182 3.12085 25.31C0.0178223 32.8018 -0.794189 41.0455 0.787842 48.9987C2.36987 56.9519 6.27466 64.2574 12.0085 69.9914C17.7427 75.7253 25.0481 79.6302 33.0012 81.2122C40.9546 82.7942 49.1982 81.9823 56.6899 78.8791C64.1819 75.7759 70.5852 70.5208 75.0903 63.7784C79.5955 57.0359 82 49.109 82 41C82 41 72.9106 41 72.9106 41C72.9106 47.3113 71.0393 53.481 67.5327 58.7286C64.0264 63.9763 59.0427 68.0664 53.2117 70.4817C47.3809 72.8969 40.9646 73.5288 34.7744 72.2975C28.5845 71.0663 22.8984 68.0271 18.4358 63.5643C13.9729 59.1015 10.9338 53.4156 9.70239 47.2255C8.47119 41.0354 9.10303 34.6192 11.5183 28.7883C13.9336 22.9574 18.0237 17.9736 23.2712 14.4672C28.519 10.9608 34.6887 9.08929 41 9.08929Z"
      id="Oval"
      fill="#919191"
      fill-opacity="0.49803922"
      fill-rule="evenodd"
      stroke="none" />
  </svg>
</div>
