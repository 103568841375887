import { AppRoutes } from '@app/app.routes';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AuthState } from '@app/enum/auth-state.enum';
import { BehaviorSubject, Observable, filter, interval, map, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { JwtToken } from '@app/interfaces/jwt-payload.interface';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { jwtDecode } from 'jwt-decode';
export const authConfig: AuthConfig = environment.authConfig;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly authStatus = new BehaviorSubject<AuthState>(AuthState.Pending);
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly oauthService: OAuthService = inject(OAuthService);
  private readonly router: Router = inject(Router);
  private initialization: Promise<void>;

  constructor() {
    this.initialization = this.initialize();
  }

  /*
   * Getter method to return the current authentication state
   */
  public isAuthenticated(): Observable<AuthState> {
    return this.authStatus.asObservable();
  }

  /*
   * Will be called on application startup and initialize the OAuthService
   */
  public async initialize(): Promise<void> {
    this.oauthService.setStorage(localStorage);
    this.oauthService.configure(authConfig);
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.authStatus.next(
      this.oauthService.hasValidAccessToken() ? AuthState.Authenticated : AuthState.NotAuthenticated
    );
    this.setupAutomaticSilentRefresh();
  }

  /*
   * Getter method to return, if the user is authenticated
   */
  public async hasValidAccessToken(): Promise<boolean> {
    await this.initialization;
    return this.oauthService.hasValidAccessToken();
  }

  public async login(): Promise<void> {
    this.oauthService.initCodeFlow();
    this.setupAutomaticSilentRefresh();
  }

  /*
   * Method is used to retrieve the current user's permission groups from the JWT token
   */
  public getPermissionGroups(): string[] {
    // mock data for local development
    if (!environment.production)
      return ['infwb_ad-f_bnk_pden', 'infwb_ad-f', 'infwb_ad-f_bnk', 'infwb_ad-a', 'infwb_id-v', 'infwb_id-z'];
    // if (!environment.production) return ['infwb_ad-f_bnk_pden', 'infwb_ad-f', 'infwb_ad-f_bnk'];
    try {
      const token = this.getAccessToken();
      const decodedToken = jwtDecode(token) as JwtToken;
      console.log('🚀 ~ AuthService ~ getPermissionGroups ~ decodedToken.wuero_groups:', decodedToken);
      const groups = decodedToken.wuero_groups.filter((group: string) => group.startsWith('infwb'));
      console.log('🚀 ~ AuthService ~ getPermissionGroups ~ filtered groups:', groups);
      return groups;
    } catch (error: unknown) {
      console.log('🚀 ~ file: auth.service.ts:43 ~ AuthService ~ getPermissionGroups ~ error:', error);
      return [];
    }
  }

  /*
   * Method is used to logout the user
   * Custom logout method to clear local storage and cookies
   * POST request to the Keycloak logout endpoint
   * GET call for development
   */
  public logout(): void {
    if (environment.production) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

      const body = new URLSearchParams();
      body.set('client_id', 'pis');
      body.set('refresh_token', this.oauthService.getRefreshToken());

      this.httpClient
        .post(authConfig.logoutUrl ?? '', body.toString(), {
          headers
        })
        .subscribe({
          next: v => console.log(v),
          error: e => console.error(e),
          complete: () => {
            console.log('Logout successful');
            // clear local storage
            if (localStorage) {
              localStorage.clear();
            }
            // clear cookies
            if (document && document.cookie) {
              document.cookie.split(';').forEach((c: any) => {
                const name = c.split('=')[0].trim();
                if (name !== 'directus_refresh_token') {
                  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=' + location.hostname;
                }
              });
            }
            // reload the page
            this.authStatus.next(AuthState.NotAuthenticated);
            this.router.navigate([AppRoutes.Login]);
          }
        });
    } else {
      this.oauthService.logOut(false);
    }
  }

  /*
   * Method is used to retrieve the current access token of the user
   */
  private getAccessToken(): string {
    try {
      return this.oauthService.getAccessToken();
    } catch (error: unknown) {
      console.log('🚀 ~ file: auth.service.ts:58 ~ AuthService ~ getAccessToken ~ error:', error);
      try {
        return this.oauthService.getIdToken();
      } catch (error: unknown) {
        console.log('🚀 ~ file: auth.service.ts:61 ~ AuthService ~ getAccessToken ~ error:', error);
        return '';
      }
    }
  }

  /*
   * Method is used to setup the automatic silent refresh of the access token
   */
  private setupAutomaticSilentRefresh(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.startTokenRefreshInterval();
    }
  }

  /*
   * Custom method to refresh the access token, because the setupAutomaticSilentRefresh angular-oauth2-oidc library does not work currently
   */
  private startTokenRefreshInterval(): void {
    // for testing call it once after 5 seconds
    if (!environment.production) {
      setTimeout(() => {
        this.oauthService.refreshToken();
      }, 5000);
    }
    // Check the token every minute
    interval(60000)
      .pipe(
        // Stop the interval when the user logs out
        takeUntil(this.authStatus.pipe(filter(status => status === AuthState.NotAuthenticated))),
        // Get the token's expiration time
        map(() => this.getExpirationTime()),
        // If the token is about to expire in the next 2 minutes, refresh it
        filter(expirationTime => (expirationTime ? Date.now() + 60000 >= expirationTime : false))
      )
      .subscribe(() => this.oauthService.refreshToken());
  }

  /*
   * Method is used to retrieve the expiration time of the current access token
   */
  private getExpirationTime(): number | null {
    const token = this.getAccessToken();
    if (!token) return null;

    const decodedToken = jwtDecode(token) as JwtToken;
    const time = decodedToken.exp ? decodedToken.exp * 1000 : null;
    console.log('🚀 ~ AuthService ~ getExpirationTime ~ time:', time);
    return time;
  }
}
