import { DataService } from '../data/data.service';
import { DirectusSchema } from '@app/interfaces/directus-schema.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppsStarterService extends DataService {
  protected override collection: keyof DirectusSchema = 'apps_starter';
  protected override fields = ['icon,id,sort,status,title,url'];
}
