import { AuthService } from './services/auth/auth.service';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterOutlet } from '@angular/router';
import { ServiceWorkerService } from '@services/service-worker/service-worker.service';
import { SpinnerComponent } from './components/spinner/spinner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, FooterComponent, HeaderComponent, RouterOutlet, SpinnerComponent]
})
export class AppComponent {
  private readonly authService: AuthService = inject(AuthService);
  private readonly serviceWorkerService: ServiceWorkerService = inject(ServiceWorkerService);
}
