import { ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  imports: [CommonModule],
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public year: number = new Date().getFullYear();
  public version: string = environment.version;
}
