<footer class=" ">
  <p>© Wüstenrot {{ year }}</p>
  <a href="https://www.wuestenrot.at/de/impressum.html">Impressum</a>
  <a href="https://www.wuestenrot.at/de/datenschutz.html">Datenschutz</a>
  <!-- socket  -->
</footer>
<div class="socket">
  <!-- version  -->
  <p>Version {{ version }}</p>
</div>
