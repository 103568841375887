import { AppsStarterService } from '@app/services/apps-starter/apps-starter.service';
import { BaseComponent } from '@app/components/base/base.component';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeInImageDirective } from '@app/directives/fade-in-image/fade-in-image.directive';
import { GetImagePathPipe } from '@app/pipes/get-image-path/get-image-path.pipe';
import { SpinnerComponent } from '@app/components/spinner/spinner.component';

@Component({
  imports: [CommonModule, GetImagePathPipe, SpinnerComponent, FadeInImageDirective],
  standalone: true,
  selector: 'app-apps-starter',
  templateUrl: './apps-starter.component.html',
  styleUrls: ['./apps-starter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppsStarterComponent extends BaseComponent implements OnInit {
  @Input() public icon: string = 'default';
  @Input() public title: string = 'default';
  @Input() public url: string = 'default';

  @ViewChild('dialog') public dialog: ElementRef | undefined;
  private readonly appsStarterService: AppsStarterService = inject(AppsStarterService);

  public ngOnInit(): void {
    this.data = this.appsStarterService.getItems();
  }

  public openDialog(): void {
    this.dialog?.nativeElement.showModal();
  }

  public closeDialog($event?: MouseEvent): void {
    $event?.preventDefault();
    this.dialog?.nativeElement.close();
  }
}
