import { AppRoutes } from '@app/app.routes';
import { AuthService } from '../auth/auth.service';
import { DirectusQuery } from '@app/interfaces/directus-query.interface';
import { DirectusSchema } from '@app/interfaces/directus-schema.interface';
import { DirectusService } from '../directus/directus.service';
import { Entity } from '@app/models/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ItemStatus } from '@app/enum/item-status.enum';
import { Observable, ReplaySubject, catchError, from, map, of, share, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  protected readonly directusService: DirectusService = inject(DirectusService);
  protected readonly authService: AuthService = inject(AuthService);
  protected collection: keyof DirectusSchema = 'pages'; // default schema
  protected fields: string[] = ['*'];
  protected query: DirectusQuery = {
    deep: {},
    filter: {
      status: ItemStatus.Published
    },
    fields: ['*'],
    limit: -1,
    sort: '-date_created'
  };
  private entities: [] = [];
  private readonly router: Router = inject(Router);

  /*
   * Fetch entities from the API and map data
   * In case entities are already fetched, return them
   * In case force is true, fetch entities from the API
   * In case query is passed, extend default query with custom params e.g. sort or limit
   */
  public getItems<T>(query?: Partial<DirectusQuery>, force?: boolean): Observable<T[]> {
    force = this.checkForce(force || false, query);
    this.query = this.extendQuery(query || this.query);

    if (this.entities.length === 0 || force) {
      return from(this.directusService.readItems(this.collection, this.query)).pipe(
        map((data: Entity[]) => {
          this.entities = data as [];
          return this.entities;
        }),
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false
        }),
        catchError((error: HttpErrorResponse) => {
          this.catchError(error);
          return Promise.resolve(error.error);
        })
      );
    } else {
      return of(this.entities);
    }
  }

  /*
   * Depending if the passed id is a uuid or a slug, fetchItems with filters or fetchItem is called
   * In case slug is passed, fetchItems is called because API does not support filtering by slug
   * In case uuid is passed, fetchItem is called
   */
  public getItem<T>(id: string, force = false, isPreview?: boolean): Observable<T | null> {
    const slug = this.checkIfValidSlug(id);
    const query = this.extendQuery(this.query, isPreview);

    if (this.entities.length > 0 && !force) {
      const entity = this.entities.find((item: Entity) => (slug ? item.slug === slug : item.id === id)) || null;
      return of(entity);
    }

    if (slug) {
      query.filter['slug'] = slug;
      return this.fetchItems<T>(query);
    }

    return this.fetchItem<T>(id, query);
  }

  /*
   * Private function to fetch entitires by query from the API and map data
   */
  private fetchItems<T>(query: DirectusQuery): Observable<T> {
    return from(this.directusService.readItems(this.collection, query)).pipe(
      map(items => (items.length > 0 ? items[0] : null)),
      tap((item: Entity) => {
        if (!item) throw new HttpErrorResponse({ status: 404 });
      }),
      catchError((error: HttpErrorResponse) => {
        this.catchError(error);
        return Promise.resolve(error.error);
      })
    ) as Observable<T>;
  }

  /*
   * Fetch entity by id from the API and map data
   */
  private fetchItem<T>(id: string, query: DirectusQuery): Observable<T> {
    return from(this.directusService.readItem(this.collection, id, query)) as Observable<T>;
  }

  /*
   * Method is used to extend query with fields and groups
   * Fields are set from current service
   * Groups are set from auth service, only if extendQueryWithGroups is true
   */
  protected extendQuery(query: Partial<DirectusQuery>, isPreview?: boolean): DirectusQuery {
    // set fields from current service
    query.fields = this.fields;
    // set groups from auth service, only if extendQueryWithGroups is true
    const groups = this.authService.getPermissionGroups();
    if (groups.length > 0 && !isPreview) {
      if (!query.filter) query.filter = {};
      query.filter['groups'] = {
        _or: [
          {
            groups_id: {
              externalId: {
                _in: groups
              }
            }
          },
          {
            groups_id: {
              _empty: true
            }
          }
        ]
      };
    }
    return query as DirectusQuery;
  }

  /*
   * Method is used to check if reload should be force
   * In case queries are not the same new filter is applied
   * In addition force can be true because of list refresh etc.
   */
  private checkForce(force: boolean, query?: Partial<DirectusQuery>): boolean {
    if (!this.checkQuery(this.query, query)) {
      force = true;
    } else {
      force = force ? force : false;
    }
    return force;
  }

  /*
   * Method is used to compare queries, which are nested objects
   */
  private checkQuery(savedQuery: DirectusQuery, newQuery?: Partial<DirectusQuery>): boolean {
    if (!newQuery) return true;
    return JSON.stringify(savedQuery) === JSON.stringify(newQuery);
  }
  /*
   * Method is used to check if passed id is a uuid or a slug
   */
  private checkIfValidSlug(id: string): string | null {
    const regex = new RegExp('^[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$', 'i');
    return regex.test(id) ? null : id;
  }

  /*
   * Method is used to catch errors and redirect to error pages
   */
  private catchError(error: HttpErrorResponse): void {
    if (error.status === 404) {
      this.router.navigate([AppRoutes.NotFoundPage]);
    }
    if (error.status === 503) {
      this.router.navigate([AppRoutes.ServerErrorPage]);
    }
  }
}
