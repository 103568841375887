import { Component, Inject, inject } from '@angular/core';
import { CustomDialogConfig } from '@app/interfaces/dialog-config.interface';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Inject(DIALOG_DATA) public data: CustomDialogConfig = inject(DIALOG_DATA);
  private readonly dialogRef: DialogRef = inject(DialogRef);

  public closeDialog($event?: MouseEvent): void {
    if (this.data.callback) {
      this.data.callback();
    }
    $event?.preventDefault();
    this.dialogRef.close();
  }
}
