/*
 * Base class for all components. Sets specific default values.
 */

import { Component } from '@angular/core';
import { Environment } from '@interfaces/environment.interface';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-base-component',
  template: ''
})
export abstract class BaseComponent {
  // declaration of fields
  public environment: Environment = environment;
  public data: Observable<any> | null = null;
}
