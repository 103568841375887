import { CustomDialogConfig } from '@app/interfaces/dialog-config.interface';
import { Dialog } from '@angular/cdk/dialog';
import { DialogComponent } from '@app/components/dialog/dialog.component';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private readonly cdkDialog: Dialog = inject(Dialog);

  public showDialog(customConfig: CustomDialogConfig): void {
    const dialogConfig = {
      hasBackdrop: true,
      height: '400px',
      width: '600px',
      data: customConfig
    };
    this.cdkDialog.open(DialogComponent, dialogConfig);
  }
}
