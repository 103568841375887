import { AppsStarterComponent } from './apps-starter/apps-starter.component';
import { AuthService } from '@app/services/auth/auth.service';
import { AuthState } from '@app/enum/auth-state.enum';
import { ChangeDetectionStrategy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  imports: [AppsStarterComponent, CommonModule, RouterModule],
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @ViewChild(AppsStarterComponent, { static: false })
  public appsStarter: AppsStarterComponent = {} as AppsStarterComponent;
  public readonly authService: AuthService = inject(AuthService);
  public readonly authState = AuthState;

  public openAppsStarter(): void {
    this.appsStarter.openDialog();
  }

  public logout(): void {
    this.authService.logout();
  }
}
