import { AppRoutes } from '@app/app.routes';
import { DirectusError } from '@app/interfaces/directus-error.interface';
import { DirectusErrorCode } from '@app/enum/directus-error-codes.enum';
import { DirectusSchema } from '@interfaces/directus-schema.interface';
import { Injectable, inject } from '@angular/core';
import { RestClient, createDirectus, readItem, readItems, rest } from '@directus/sdk';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectusService {
  public client: RestClient<DirectusSchema> = createDirectus(environment.directusUrl).with(rest());
  private readonly router: Router = inject(Router);

  public async readItem<T>(collection: keyof DirectusSchema, id: string, query?: any): Promise<T | null> {
    return await this.client
      .request<T>(readItem(collection, id, query))
      .catch((error: unknown) => this.handleError(error as DirectusError));
  }

  public async readItems<T>(collection: keyof DirectusSchema, query?: any): Promise<any[]> {
    return await this.client.request(readItems(collection, query));
  }

  private handleError(error: DirectusError): null {
    if (!error.errors) {
      this.router.navigate([AppRoutes.ServerErrorPage]);
    }
    switch (error.errors[0].extensions.code) {
      case DirectusErrorCode.Forbidden:
        this.router.navigate([AppRoutes.NotFoundPage]);
        break;
      default:
        this.router.navigate([AppRoutes.ServerErrorPage]);
        break;
    }
    return null;
  }
}
