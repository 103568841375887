import { DialogService } from '../dialog/dialog.service';
import { Injectable, inject, isDevMode } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  public dialogService: DialogService = inject(DialogService);
  private readonly swUpdate: SwUpdate = inject(SwUpdate);

  constructor() {
    this.initListener();
  }

  public initListener(): void {
    if (!isDevMode() && navigator.serviceWorker) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => {
            console.log('🚀 ~ ServiceWorkerService ~ filter ~ evt:', evt);
            return evt.type === 'VERSION_READY';
          }),
          map((evt: VersionReadyEvent) => {
            console.log('🚀 ~ ServiceWorkerService ~ map ~ evt:', evt);
            return {
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion
            };
          })
        )
        .subscribe(() => {
          this.showModal();
        });
    }
  }

  private showModal(): void {
    this.dialogService.showDialog({
      buttonText: 'Aktualisieren',
      title: 'Ein Update ist verfügbar!',
      message: 'Nach dem Bestätigen werden die neuen Inhalte geladen.',
      type: 'prompt',
      callback: () => {
        window.location.reload();
      }
    });
  }
}
