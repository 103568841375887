<header>
  <div class="header-grid">
    <!-- logo  -->
    <div class="site-name">
      <img
        ngOptimizedImage
        loading="eager"
        routerLink=""
        width="240"
        height="82"
        src="../../../assets/images/logo.png"
        alt="Logo" />
      <p>Produktinformationssystem</p>
    </div>
    <div></div>
    <div class="header-icons cell-3">
      <!-- logout -->
      <img
        *ngIf="(authService.isAuthenticated() | async) === authState.Authenticated"
        ngOptimizedImage
        loading="eager"
        width="24"
        height="24"
        class="clickable"
        routerLink="/login"
        src="../../../assets/icons/sign-in.svg"
        alt="Apps"
        (click)="logout()" />
      <!-- apps -->
      <img
        *ngIf="(authService.isAuthenticated() | async) === authState.Authenticated"
        ngOptimizedImage
        loading="eager"
        width="24"
        height="24"
        class="clickable"
        src="../../../assets/icons/apps.svg"
        alt="Apps"
        (click)="openAppsStarter()" />
    </div>
    <app-apps-starter *ngIf="(authService.isAuthenticated() | async) === authState.Authenticated"></app-apps-starter>
  </div>
</header>
