import { AuthService } from '@app/services/auth/auth.service';
import { AuthState } from '@app/enum/auth-state.enum';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { inject } from '@angular/core';

export const nonAuthGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.isAuthenticated().pipe(
    filter((authState: AuthState) => authState !== AuthState.Pending),
    map((authState: AuthState) => {
      if (authState === AuthState.NotAuthenticated) {
        return true;
      }
      return router.parseUrl('/');
    })
  );
};
