<dialog #dialog>
  <form method="dialog">
    <div class="scroll-area">
      <ng-container *ngIf="data | async as appsStarter; else loading">
        <div class="apps-grid">
          <!-- app item -->
          <div class="app-item" *ngFor="let item of appsStarter">
            <a href="{{ item.url }}" target="_blank">
              <img
                height="56"
                width="56"
                ngOptimizedImage
                loading="lazy"
                appFadeInImage
                src="{{ item.icon | getImagePath }}"
                alt="{{ item.title }}" />
              <p>{{ item.title }}</p>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
    <button class="close-popup" type="submit">Schließen</button>
  </form>
</dialog>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
