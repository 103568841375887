import { AppConstants } from '../../app.constants';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment/environment';

@Pipe({
  name: 'getImagePath',
  standalone: true
})
export class GetImagePathPipe implements PipeTransform {
  public transform(value: string): string {
    return `${environment.directusUrl}/assets/${value}?quality=${AppConstants.ASSET_QUALITY}`;
  }
}
