import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';
import { nonAuthGuard } from './guards/non-auth/non-auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
    canActivate: [authGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent),
    canActivate: [nonAuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/contents/pages.module').then(m => m.PagesModule),
    canActivate: [authGuard]
  },
  {
    path: '404',
    loadComponent: () => import('./pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: '503',
    loadComponent: () => import('./pages/server-error/server-error.component').then(m => m.ServerErrorComponent)
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
