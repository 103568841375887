import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { AuthState } from '@app/enum/auth-state.enum';
import { Observable, filter, map } from 'rxjs';
import { environment } from '@environment/environment';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  const previewToken = route.queryParams['previewToken'] || null;
  const validPreviewTokens = previewToken === environment.previewToken;

  return authService.isAuthenticated().pipe(
    filter((authState: AuthState) => authState !== AuthState.Pending),
    map((authState: AuthState) => {
      if (validPreviewTokens) {
        // If a valid preview token is present, allow the route activation
        return true;
      }
      if (authState === AuthState.Authenticated) {
        // If the user is authenticated, check for a redirectUri in the local storage
        const redirectUri = localStorage.getItem('redirectUri');
        if (redirectUri) {
          localStorage.removeItem('redirectUri');
          return router.parseUrl(redirectUri);
        }
        return true;
      }
      // If the user is not authenticated, redirect to the login page and store the current url in the local storage
      localStorage.setItem('redirectUri', state.url);
      return router.parseUrl('/login');
    })
  );
};
