import { Environment } from '@interfaces/environment.interface';

let directusUrl: string;
let showDebugInformation = false;

switch (window.location.hostname) {
  case 'staging.pis.wuestenrot.at':
    directusUrl = 'https://wuestenrot.staging.web-punks.com';
    showDebugInformation = true;
    break;
  case 'pis.wuestenrot.at':
    directusUrl = 'https://directus.pis.wuestenrot.at';
    break;
  default:
    directusUrl = 'https://wuestenrot.staging.web-punks.com';
}

const issuerUrl = 'https://sweb.wuestenrot.at/auth/realms/wuero';
const logoutUrl = 'https://sweb.wuestenrot.at/auth/realms/wuero/protocol/openid-connect/logout';
const originUrl = window.location.origin;

export const environment: Environment = {
  authConfig: {
    logoutUrl,
    clientId: 'pis',
    issuer: issuerUrl,
    oidc: true,
    redirectUri: originUrl,
    responseType: 'code',
    scope: 'openid profile email offline_access',
    showDebugInformation,
    silentRefreshRedirectUri: originUrl
  },
  directusUrl,
  previewToken: '018d1248-1808-7ee5-83d6-bfbea5080c45',
  production: true,
  version: '1.0.3'
};
